




































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  states,
  types,
  availableForOptions,
  categories,
  actions,
  isLatitudeValid,
  isLongitudeValid
} from '@/utils/property'
import { Property } from '@/models/Property'
import { UserModule } from '@/store/modules/user'
import {
  convertToArrayTranslations,
  convertToMapTranslations,
  errorMsg,
  hasOfficeService,
  successMsg,
  validateForm
} from '@/utils'
import { PropertyTranslation } from '@/models/PropertyTranslation'
import { nextCode, saveProperty } from '@/api/properties'
import { Form } from 'element-ui'

@Component({
  name: 'CreateProperty',
  components: {
    LocationInformation: () => import('@/views/property/components/LocationInformation.vue'),
    Comments: () => import('@/views/property/components/Comments.vue')
  }
})

export default class extends Vue {
  private property = new Property({
    user_id: UserModule.id,
    office_id: UserModule.officeId,
    meta: {
      show_on_spitogatos: hasOfficeService('spitogatos'),
      show_on_plot: hasOfficeService('plot'),
      show_on_xe: hasOfficeService('xe'),
      diffs: [],
      actions
    }
  })

  private translations = convertToMapTranslations([], PropertyTranslation)
  private states = states
  private types = types
  private categories = categories
  private availableForOptions = availableForOptions
  private saveLoader = false
  private step = 0
  private atLeastOneLanguage = (rule: any, value: string, callback: Function) => {
    const isValid = ['el'].some((language) => {
      return this.translations[language][rule.field]
    })
    return isValid ? callback() : callback(this.$t('form.isRequired'))
  }

  private validateLatitude = (rule: any, value: any, callback: Function) => {
    if (isLatitudeValid(value)) {
      callback()
    } else {
      callback(this.$t('formValidationErrors.invalidLatitude'))
    }
  }

  private validateLongitude = (rule: any, value: any, callback: Function) => {
    if (isLongitudeValid(value)) {
      callback()
    } else {
      callback(this.$t('formValidationErrors.invalidLongitude'))
    }
  }

  private rules = {
    code: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    category: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    available_for: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    type: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    state: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    area_id: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    'location.coordinates[0]': [
      { validator: this.validateLatitude, trigger: 'blur' }
    ],
    'location.coordinates[1]': [
      { validator: this.validateLongitude, trigger: 'blur' }
    ],
    address: [{
      required: true,
      validator: this.atLeastOneLanguage,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    address_number: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }]
  }

  created() {
    nextCode({
      userId: UserModule.id,
      officeId: UserModule.officeId
    }).then(({ data }) => {
      this.property.code = data.next_code
    })
  }

  private async next() {
    const [valid] = await validateForm(this.$refs.propertyForm as Form)
    if (!valid) return errorMsg('form.formErrors')
    if (this.step++ === 1) {
      return await this.submitForm()
    }
  }

  private previous() {
    if (this.step-- <= 0) {
      this.step = 0
    }
  }

  private onCategoryChange() {
    this.$set(this.property, 'type', null)
    this.$set(this.property, 'state', null)
  }

  private async submitForm() {
    this.saveLoader = true
    try {
      this.property.translations = convertToArrayTranslations(this.translations, PropertyTranslation)
      const { data } = await saveProperty(this.property)
      await successMsg('propertyDetail.successSave')
      return this.$router.push('/property/edit/' + data.id)
    } catch (err) {
      this.previous()
      if (err.response && err.response.status === 409) {
        await errorMsg('api.codeAlreadyExists')
      } else {
        await errorMsg('api.serverError')
      }
    }
    this.saveLoader = false
  }
}
